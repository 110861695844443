import React from 'react';

import i18n, {parseLanguage} from "../../../translations/i18n";
import {Helmet} from "react-helmet";
import {ERROR_CODES, OBJECTS_PATH} from "../../../App";
import LocalizationService from "../../../translations/configLangContext";
import {i19n} from "../../../translations/i19n";

const STEP_TYPE = {
    LOADING: 1,
    STANDARD_FLOW: 2,
    FORCED_ERROR: 3
}

export default class SignupSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.step = STEP_TYPE.LOADING;
        this.errorCode = null;
        this.aidCode = null;
        this.eid = null;
        this.userInfo = {};
        this.isUserVr = null;
        this.noUserFoundFlag = null;
        this.countdown = 0;
        this.urlToRedirect = null;      // it could be both target_url or referrer values from DB
        this.state = {
            timer: 5,  //set countdown timer before redirect
            prefCenterUrl: null
        }
        this.countdown = 0;

        this.translateCompleted = false;
    }

    signupSuccessDbInfo(aidCode, eid) {
        fetch(this.props.dbSelectService, {
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify({reqType: "signupSuccessInfo", aidCode: aidCode, eid: eid, idDomainUrl: this.props.idDomainUrl}),
            headers: new Headers({'Content-Type': 'application/json'})
        }).then(response => {
            if (!response.ok) console.log("generic Error");
            else return response.json();
        }).then(async data => {
            if(data.error === true) {
                if(data.errorType && data.errorType !== "") this.errorCode = data.errorType;
                else this.errorCode = ERROR_CODES.SUCCESS_PAGE.GENERIC_SUCCESS_PAGE_ERROR;
                this.step = STEP_TYPE.FORCED_ERROR;
                this.noUserFoundFlag = true;
                this.forceUpdate();
            } else {
                this.noUserFoundFlag = false;
                this.userInfo = data.successPageInfo;
                this.checkUrlToRedirect();
                const langContext = LocalizationService.getInstance();
                await langContext.saveAndSynch(this.props.dalService, data.successPageInfo.professionalType, this.props.forcedLanguage, "signup_success");
                this.switchRenderByUserInfo();
            }
        });
    }

    footerLoc() {
        let localized = i18n.t('footer');
        let localizedP = this.userInfo.professionalType != null ? i18n.t('footer_'+this.userInfo.professionalType) : false;
        if(localizedP && i18n.exists('footer_'+this.userInfo.professionalType)) {
            document.getElementById('footerText').innerHTML = localizedP;
        } else if (localized && i18n.exists('footer')) {
            document.getElementById('footerText').innerHTML = localized;
        }
    }

    componentDidUpdate(){
        //stop countdown when timer reaches 0, then redirect to target
        if(this.state.timer === 0 && !this.userInfo.isDoiActive) {
            clearInterval(this.countdown);
            window.location.replace(this.urlToRedirect);
        }
    }

    async componentDidMount() {
        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, null, this.props.forcedLanguage, "signup_success");
        this.setState({ translateCompleted: true});

    }

    checkUrlToRedirect() {
        if(this.userInfo && this.userInfo.target_url && this.userInfo.target_url !== "" && this.userInfo.target_url !== "login") this.urlToRedirect = this.userInfo.target_url;
        else if(this.userInfo && this.userInfo.referrer && this.userInfo.referrer !== "") this.urlToRedirect = this.userInfo.referrer;
    }

    switchRenderByUserInfo() {
        this.isUserVrCheck();
        this.step = STEP_TYPE.STANDARD_FLOW;
        if (this.urlToRedirect && this.urlToRedirect !== "" && this.countdown === 0) {
            this.countdown = setInterval(()=>{this.setState({timer: this.state.timer -1})}, 1000);
        }
        this.forceUpdate();
    }

    isUserVrCheck() {
        // check if user is VR or Approved
        if(this.userInfo && Object.keys(this.userInfo).length > 0 && this.userInfo.hasOwnProperty('statoMedico') && this.userInfo.statoMedico) {
            this.isUserVr = this.userInfo.statoMedico === "VR";
        }
        return false;
    }

    overlayClose() {
        if (window.opener && window.opener !== window) {
            setTimeout(function() {
                window.close();
                window.opener.postMessage("loadRegSuccess", "*");
            }, 5000);
        }
    }

    renderErrorMessage() {
        let errorCodeParsed = this.errorCode ? this.errorCode : ERROR_CODES.SUCCESS_PAGE.GENERIC_SUCCESS_PAGE_ERROR;
        this.errorCode = null ;
        this.overlayClose();
        return (
            <div>
                <img id="img" src={OBJECTS_PATH.PICTURES.GENERIC_ERROR_ICON} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }}/>
                <h2 dangerouslySetInnerHTML={{__html: i18n.t('forgotPassword.genericError')}} style={{width: 'fit-content', display: 'block', margin: 'auto' }}/>
                <p style={{width: 'fit-content', display: 'block', margin: 'auto' }}>{i18n.t('changePassword.errors.shortIntro') + ": " + errorCodeParsed}</p>
            </div>
        )
    }
    renderLoading() {
        return (<>
            <div className="o-headers">
                <div class="h-img">
                    <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                </div>
            </div>
        </>);
    }

    renderStandardFlow() {

        this.overlayClose();
        return (
            <div className="o-headers">
                <div className="h-img">
                    <img src={this.isUserVr ? OBJECTS_PATH.PICTURES.SUCCESS_PAGE_END : OBJECTS_PATH.PICTURES.SUCCESS_PAGE_COMPLETE} width="62px" height="62px" />
                </div>
                {this.isUserVr ?
                    <h1 className="h h-1" dangerouslySetInnerHTML={{__html: i18n.t('professional.successPageTextVR')}} />

                : ""}

                {!this.isUserVr && this.userInfo.isDoiActive == 1 && this.userInfo.original_persist_service === "CRM_REGISTRATION" ?
                    <h1 className="h h-1"
                        dangerouslySetInnerHTML={{__html: i19n.t('professional.signup_success_doi_aidreg_consent_hidden')}}/>
                    : ""}

                {!this.isUserVr && this.userInfo.isDoiActive == 1 && (this.userInfo.original_persist_service === "REG_FORM" || this.userInfo.original_persist_service === "DSA") ?
                    <h1 className="h h-1"
                        dangerouslySetInnerHTML={{__html: i19n.t('professional.signup_success_doi_aidreg_consent_show')}} />
                : ""}

                { !this.isUserVr && this.userInfo.isDoiActive != 1  ?
                    <h1 className="h h-1" dangerouslySetInnerHTML={{__html: i18n.exists('professional.successPageText_'+this.userInfo.professionalType) ? i18n.t('professional.successPageText_'+this.userInfo.professionalType) : i18n.t('professional.successPageText') }} />
               : ""}


                {this.urlToRedirect && !this.userInfo.isDoiActive ?
                    <div className="o-prose">
                        <div className="e-content">
                            <p className="f-2" dangerouslySetInnerHTML={
                                {__html: i18n.t('professional.succesPageRedirect', {time: `${this.state.timer}`, link: `<a class="link-break" href="${this.urlToRedirect}">${this.urlToRedirect}</a>`, link_text: `${this.urlToRedirect}`})}
                            } />
                        </div>
                    </div>
                    : ""
                }

                { this.props.prefCenterUrlDetail && this.props.prefCenterUrlDetail.domain_url &&  this.userInfo.isDoiActive != 1 ?
                <fieldset className="c-fieldset">
                    <div className="u-wrap-fields">
                        <div className="form-row">
                            {/*TODO to remove if DOI only */}
                            <div className="form-group form-group-buttons">
                                <div className="o-button o-button-full">
                                    <a className="c-button c-button-outline txt-uppercase" onClick={() => this.openInNewTab(this.props.prefCenterUrlDetail.domain_url)}>
                                        {i18n.t("professional.preferenceCenter").includes("professional.preferenceCenter") ?
                                            "Preference Center": i18n.t("professional.preferenceCenter")
                                        }
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                    : ""

                }

            </div>
        )
    }

    renderStepManager() {
        switch (this.step) {
            case STEP_TYPE.LOADING: return this.renderLoading();
            case STEP_TYPE.STANDARD_FLOW: return this.renderStandardFlow();
            case STEP_TYPE.FORCED_ERROR: return this.renderErrorMessage();
            default: return this.renderErrorMessage();
        }
    }

    render() {

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        this.footerLoc();
        if(this.props.location.state && this.props.location.state.aidcode) {
            // if 'aidcode' set by props, slow down process to allowed AiD to persist user before query
            this.countdown = setInterval(()=>{this.setState({timer: this.state.timer -1})}, 3000);
            let currentUrl = new URL(window.location.href);
            currentUrl.searchParams.append(`aidcode`, this.props.location.state.aidcode);
            this.urlToRedirect = currentUrl;
            return ( <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }}/> );
        }
        let queryString = new URLSearchParams(this.props.location.search)
        if(queryString.get("aidcode")) this.aidCode = queryString.get("aidcode");
        if(queryString.get("eid")) this.eid = queryString.get("eid");

        if(this.aidCode == null && this.eid == null) {
            this.errorCode = ERROR_CODES.SUCCESS_PAGE.NO_AID_CODE_PROVIDED;
            this.step = STEP_TYPE.FORCED_ERROR
        }
        if(!this.noUserFoundFlag && (this.aidCode || this.eid) && (!this.userInfo || Object.keys(this.userInfo).length === 0)) this.signupSuccessDbInfo(this.aidCode, this.eid);
        return (
            <div>
                <Helmet>
                    <title>{i18n.t('professional.pageTitleSuccess')}</title>
                    <meta name="description" content={i18n.t('professional.pageTitleSuccess')} />
                </Helmet>

                { this.renderStepManager() }

            </div>
        );
    }
    openInNewTab(url)  {
        if (window.opener && window.opener !== window) {
            window.top.postMessage("closeOverlay", "*");
            window.open(url, '_blank')
        } else {
            window.open(url,'_self')
        }
    }
}
